import TypeIt from "typeit";
import Pristine from "pristinejs";
import ExpiredStorage from 'expired-storage';

async function getData() {
    const url = "/api.php";
    try {
      const response = await fetch(url,{
        cache: "force-cache"
       }
      );
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      const json = await response.json();
      return json;
    } catch (error) {
      console.error(error.message);
    }
  }

    function createHoneypotField(form) {
        const honeypot = document.createElement('input');
        const honeypotLabel = document.createElement('label');
        const emailInput = form.querySelector('input[type="email"]');
        const style = document.createElement('style');

        honeypot.type = 'website';
        honeypot.id = 'website';
        honeypot.name = 'website';
        honeypot.className = 'hp-input';
        honeypot.setAttribute('autocomplete', 'off');
        honeypot.setAttribute('tabindex', '-1');
        honeypot.setAttribute('placeholder', 'Enter Your Website');

        honeypotLabel.setAttribute('for', 'website');
        honeypotLabel.textContent = 'Website (optional)';
        honeypotLabel.className = 'hp-label';
        
        style.textContent = `
            .hp-label {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                height: 0;
                width: 0;
                 z-index: -1;
            }
            .hp-input {
                display: none;
                position: absolute;
                left: -9999px;
            }
        `;
        
        if (emailInput) {
            emailInput.after(honeypotLabel);
            emailInput.after(honeypot);
            document.head.appendChild(style);
        }
    }

document.addEventListener("DOMContentLoaded", function () {
    const prefixClass = document.getElementsByClassName('prefix')[0];
    const phraseClassID = 'phrase';
    const phraseClass = document.getElementsByClassName(phraseClassID)[0];
    const expiredStorage = new ExpiredStorage();
          expiredStorage.clearExpired();

    let selectedIds = expiredStorage.getItem('selectedPhrases') 
    ? expiredStorage.getItem('selectedPhrases').split(',').map(Number)
    : [];

    if(prefixClass && phraseClass){
        getData().then(json => {
            if (Array.isArray(json) && json.length !== 0) {
                const availableItems = json.filter((item, index) => !selectedIds.includes(index));
                let prefix,
                    phrase;

                if (availableItems.length === 0) {
                    const info = document.createElement('div');

                    prefix = 'is';
                    phrase = 'aphonic';

                    info.classList.add('fade-in');
                    info.textContent = "[ no more phrases, refresh to start over ]";
                    document.getElementsByClassName('vertical-center')[0].appendChild(info);
                    expiredStorage.updateExpiration('selectedPhrases', 5);
                } else {
                    const randomKey = Math.floor(Math.random() * availableItems.length);
                    const selectedItem = availableItems[randomKey];
                    const selectedItemID = json.indexOf(selectedItem);

                    selectedIds.push(selectedItemID);
                    expiredStorage.setItem('selectedPhrases', selectedIds.join(','), 3600 * 24 * 90); // Expires in 90 days

                    prefix = json[randomKey].prefix;
                    phrase = json[randomKey].phrase;
                }

                prefixClass.textContent = prefix;

                new TypeIt("." + phraseClassID, {
                    strings: phrase,
                }).options({ speed: 5, lifeLike: false }).type(".").go();
            }
        });
    }

    if(document.getElementById('suggest-form')){

        const formID = 'suggest-form';
        const form = document.getElementById(formID);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const hcaptchaElement = document.querySelector('[name="h-captcha-response"]');
        const resultsDiv = form.querySelector('.results');

        const pristine = new Pristine(form, {
            classTo: "field",
            errorClass: "error",
            errorTextParent: 'field',
            errorTextTag: 'span',
            errorTextClass: 'has-error',
        });

        pristine.addValidator(hcaptchaElement, function(value) {
            return hcaptchaElement && hcaptchaElement.value !== '';
        }, 'The hCaptcha is required.');

        form.addEventListener('submit', function(e) {
            const valid = pristine.validate(null, true);
            const ul = document.createElement('ul');

            resultsDiv.classList.add('error');
            resultsDiv.innerHTML = "";
  
            if (!valid) {
                e.preventDefault();
                const errors = pristine.getErrors();
                resultsDiv.appendChild(ul);
                resultsDiv.classList.remove('success');

                errors.forEach(error => {
                    let errorMessageText = error.errors[0];
                    const errorEl = document.createElement('li');
                    errorEl.textContent = errorMessageText;
                    ul.appendChild(errorEl);
                });
            } else{
                resultsDiv.classList.remove('error');
            }
        });

        createHoneypotField(form);
        if(urlParams.has('m')){
            const message = urlParams.get('m')
            resultsDiv.classList.add('success');
            resultsDiv.textContent = message;
        }
    };
});